import { capital } from 'case';
import {
  ColDef,
  EditableCallbackParams,
  ICellEditorParams,
  ICellRendererParams,
  NewValueParams,
  RowHeightParams,
  ValueFormatterParams,
  ValueGetterParams,
} from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  effect,
  EventEmitter,
  inject,
  Injector,
  Input,
  input,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { Currency } from '@supy.api/dictionaries';

import {
  ChannelState,
  CreditPeriodTypeEnum,
  DEFAULT_CURRENCY,
  DEFAULT_CURRENCY_PRECISION,
  InputValidators,
  PaymentMethod,
  PreferredType,
  SupplierChannelRequest,
} from '@supy/common';
import {
  EditableOptionsCellRendererComponent,
  EditableOptionsCellRendererContext,
  GridPocComponent,
  InputCellEditorComponent,
  InputCounterCellEditorComponent,
  InputCounterCellEditorContext,
  SelectCellEditorComponent,
  SelectCellEditorContext,
  SwitchCellRendererComponent,
  SwitchCellRendererContext,
  TimePickerCellEditorComponent,
} from '@supy/components';
import { Writable } from '@supy/core';
import { DetailedRetailerSupplier } from '@supy/retailers';

import { creditPeriodTypeOptions } from '../retailer-supplier-details';

export interface BranchesWithActiveStatus extends SupplierChannelRequest {
  readonly active?: boolean;
  readonly name?: string;
  readonly retailerId?: string;
  readonly preferred?: boolean;
}

@Component({
  selector: 'supy-supplier-branches-grid',
  templateUrl: './supplier-branches-grid.component.html',
  styleUrls: ['./supplier-branches-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierBranchesGridComponent {
  @ViewChild(GridPocComponent, { static: true }) grid: GridPocComponent<BranchesWithActiveStatus>;

  readonly #injector = inject(Injector);

  @Input() set branches(value: BranchesWithActiveStatus[]) {
    if (value?.length) {
      this.data = value;
    }
  }

  readonly currency = input<Currency>(DEFAULT_CURRENCY);
  readonly currencyPrecision = input<number>(DEFAULT_CURRENCY_PRECISION);
  readonly isSelectingBranchDisabled = input<boolean>(false);
  readonly supplier = input<DetailedRetailerSupplier>();
  readonly showPreferredColumn = input<boolean>(false);
  readonly showCreditPeriodValueColumn = input<boolean>(false);
  readonly hideContactColumns = input<boolean>(false);
  readonly hidePreferencesColumns = input<boolean>(false);

  @Output() readonly branchesChanged = new EventEmitter<void>();

  protected data: BranchesWithActiveStatus[] = [];

  protected readonly columnDefs = signal<ColDef<BranchesWithActiveStatus>[]>([]);
  protected readonly defaultColDefs = signal<ColDef<BranchesWithActiveStatus>>({
    cellStyle: { border: 'none' },
    suppressHeaderKeyboardEvent: () => true,
  });

  protected readonly getRowHeight: (params: RowHeightParams) => number = () => {
    return 50;
  };

  protected setColumnDefs(): void {
    effect(
      () => {
        const currency = this.currency();
        const currencyPrecision = this.currencyPrecision();
        const isSelectingBranchDisabled = this.isSelectingBranchDisabled();
        const showCreditPeriodValueColumn = this.showCreditPeriodValueColumn();
        const hideContactColumns = this.hideContactColumns();
        const hidePreferencesColumns = this.hidePreferencesColumns();

        const columnDefs: ColDef<BranchesWithActiveStatus>[] = [
          {
            headerName: $localize`:@@common.branchName:Branch Name`,
            field: 'name',
            wrapText: true,
            flex: 2,
          },
          {
            headerName: $localize`:@@grid.headers.active.label:Active`,
            field: 'active',
            cellRenderer: SwitchCellRendererComponent,
            cellRendererParams: {
              context: {
                size: 'normal',
                disabled: isSelectingBranchDisabled,
              } as SwitchCellRendererContext,
            },
            onCellValueChanged: (event: NewValueParams<BranchesWithActiveStatus, boolean>) => {
              const branchIndex = this.getBranchIndex(event.data.branch.id);

              let state = ChannelState.active;

              const wasBranchActiveBefore = this.supplier()?.activeChannels?.find(
                channel => channel.retailerId === event.data.branch.id,
              );

              if (!event.newValue && wasBranchActiveBefore) {
                state = ChannelState.deleted;
              }

              this.data[branchIndex] = {
                ...event.data,
                state,
                active: event.newValue as boolean,
              };

              const rowNode = this.grid.api.getDisplayedRowAtIndex(branchIndex);

              if (rowNode) {
                this.grid.api.redrawRows({ rowNodes: [rowNode] });
              }

              this.branchesChanged.emit();
            },
            getQuickFilterText: () => '',
            flex: 0.5,
          },
          {
            headerName: $localize`:@@grid.headers.contactNum.label:Contact #`,
            field: 'contactNumbers',
            hide: hideContactColumns,
            cellRenderer: EditableOptionsCellRendererComponent,
            onCellValueChanged: (event: NewValueParams<BranchesWithActiveStatus, string[]>) =>
              this.onCellValueChanged(event),

            cellRendererParams: (params: ICellRendererParams<BranchesWithActiveStatus>) => {
              const data = params.data as BranchesWithActiveStatus;

              return {
                context: {
                  placeholder: $localize`:@@suppliers.create.details.addContactNumber:Add Contact Number`,
                  iconSuffix: 'call-add',
                  inputSuffix: 'call-add',
                  disabled: !data.active,
                  validators: [InputValidators.phone],
                } as EditableOptionsCellRendererContext,
              };
            },
            getQuickFilterText: () => '',
            flex: 1,
          },
          {
            headerName: $localize`:@@emails:Emails`,
            field: 'emails',
            hide: hideContactColumns,
            onCellValueChanged: (event: NewValueParams<BranchesWithActiveStatus, string[]>) =>
              this.onCellValueChanged(event),
            cellRenderer: EditableOptionsCellRendererComponent,

            cellRendererParams: (params: ICellRendererParams<BranchesWithActiveStatus>) => {
              const data = params.data as BranchesWithActiveStatus;

              return {
                context: {
                  placeholder: $localize`:@@suppliers.create.details.addEmail:Add Email`,
                  iconSuffix: 'email',
                  inputSuffix: 'plus-flat',
                  disabled: !data.active,
                  validators: [InputValidators.email],
                } as EditableOptionsCellRendererContext,
              };
            },
            getQuickFilterText: () => '',
            flex: 1,
          },
          {
            headerName: $localize`:@@grid.headers.minimumOrderLimitCurrency.label:Minimum Order Limit (${currency}:INTERPOLATION:)`,
            field: 'minimumOrderLimit',
            hide: hidePreferencesColumns,
            cellEditor: InputCellEditorComponent,
            valueFormatter: ({ value }: ValueFormatterParams<BranchesWithActiveStatus, number>) =>
              value ? `${value}` : '0',
            cellEditorParams: (
              params: ICellEditorParams<BranchesWithActiveStatus, unknown, InputCounterCellEditorContext>,
            ) => {
              const data = params.data;

              return {
                context: {
                  numeric: true,
                  disabled: !data.active,
                  precision: currencyPrecision,
                } as InputCounterCellEditorContext,
              };
            },
            type: 'rightAligned',
            editable: params => this.isColumnEditable(params),

            onCellValueChanged: (event: NewValueParams<BranchesWithActiveStatus, number>) =>
              this.onCellValueChanged(event),
            getQuickFilterText: () => '',
            flex: 1,
          },
          {
            headerName: $localize`:@@grid.headers.paymentMethod.label:Default Payment Method`,
            field: 'paymentMethod',
            hide: hidePreferencesColumns,
            cellEditor: SelectCellEditorComponent,
            cellEditorParams: (
              params: ICellEditorParams<BranchesWithActiveStatus, unknown, SelectCellEditorContext>,
            ) => {
              const data = params.data;

              return {
                context: {
                  options: () => Object.values(PaymentMethod),
                  value: () => data.paymentMethod,
                  disabled: () => !data.active,
                },
              };
            },
            onCellValueChanged: (event: NewValueParams<BranchesWithActiveStatus, PaymentMethod>) =>
              this.onCellValueChanged(event),
            valueFormatter: ({ value }: ValueFormatterParams<BranchesWithActiveStatus, PaymentMethod>) =>
              value ? `${capital(value)}` : 'Select Method',
            editable: params => this.isColumnEditable(params),
            getQuickFilterText: () => '',
            flex: 1.1,
          },
          {
            headerName: $localize`:@@grid.headers.cutOffTime.label:Cut Off Time`,
            field: 'cutOffTime',
            hide: hidePreferencesColumns,
            valueFormatter: ({ value }: ValueFormatterParams<BranchesWithActiveStatus, number>) => {
              const date = new Date();

              date.setHours(0, 0, 0, value as number);

              return value
                ? date.toLocaleString('en-US', {
                    hour: 'numeric',
                    minute: 'numeric',
                    hour12: true,
                  })
                : 'HH:MM AM';
            },

            cellEditor: TimePickerCellEditorComponent,
            cellEditorParams: (params: ICellEditorParams<BranchesWithActiveStatus, number>) => {
              const data = params.data;

              return {
                context: {
                  disabled: !data.active,
                },
              };
            },
            editable: params => this.isColumnEditable(params),
            onCellValueChanged: (event: NewValueParams<BranchesWithActiveStatus, number>) =>
              this.onCellValueChanged(event),
            getQuickFilterText: () => '',
            flex: 1.2,
          },
          {
            headerName: $localize`:@@grid.headers.creditPeriod.label:Credit Period`,
            field: 'creditPeriod.type',
            hide: hidePreferencesColumns,
            valueFormatter: ({ value }: ValueFormatterParams<BranchesWithActiveStatus, string>) =>
              value ? `${creditPeriodTypeOptions.find(({ id }) => id === value)?.name as string}` : 'Select Type',
            cellEditor: SelectCellEditorComponent,
            cellEditorParams: (
              params: ICellEditorParams<
                BranchesWithActiveStatus,
                unknown,
                SelectCellEditorContext<BranchesWithActiveStatus, CreditPeriodTypeEnum>
              >,
            ) => {
              const data = params.data;

              return {
                context: {
                  options: () => creditPeriodTypeOptions.map(option => option.id),
                  value: () => data.creditPeriod?.type,
                  disabled: () => !data.active,
                  displayFn: option => creditPeriodTypeOptions.find(({ id }) => id === option)?.name,
                } as SelectCellEditorContext<BranchesWithActiveStatus, string>,
              };
            },
            onCellValueChanged: (event: NewValueParams<BranchesWithActiveStatus, CreditPeriodTypeEnum>) => {
              const branchIndex = this.getBranchIndex(event.data.branch.id);

              if (branchIndex === -1) {
                return;
              }

              this.data[branchIndex] = {
                ...event.data,
                creditPeriod: {
                  type: event.newValue as CreditPeriodTypeEnum,
                  value: event.newValue === CreditPeriodTypeEnum.Custom ? 0 : undefined,
                },
              };

              this.grid.setRowData(this.data);

              this.branchesChanged.emit();
            },
            editable: params => this.isColumnEditable(params),
            getQuickFilterText: () => '',
            flex: 1,
          },
          {
            headerName: ``,
            field: 'creditPeriod.value',
            valueGetter: ({ data }: ValueGetterParams<BranchesWithActiveStatus>) => data?.creditPeriod?.value,
            valueFormatter: ({ data }: ValueFormatterParams<BranchesWithActiveStatus, number>) =>
              data?.creditPeriod?.value
                ? `${data.creditPeriod.value}`
                : this.isCustomCreditPeriodType(data as BranchesWithActiveStatus)
                  ? '0'
                  : '',
            cellEditor: InputCounterCellEditorComponent,
            cellEditorParams: {
              context: {
                min: 0,
                step: 1,
              } as InputCounterCellEditorContext,
            },
            cellStyle: { textAlign: 'center' },
            headerClass: 'supy-supplier-branches-grid__header-center',
            editable: params =>
              this.isColumnEditable(params) && this.isCustomCreditPeriodType(params.data as BranchesWithActiveStatus),
            onCellValueChanged: (event: NewValueParams<BranchesWithActiveStatus, number>) =>
              this.onCellValueChanged(event),
            hide: hidePreferencesColumns || !showCreditPeriodValueColumn,
            getQuickFilterText: () => '',
            flex: 1.1,
          },
          {
            headerName: $localize`:@@grid.headers.handlingFeePercent.label:Handling Fee (%)`,
            field: 'defaultHandlingFeePercentage',
            hide: hidePreferencesColumns,
            valueFormatter: ({ value }: ValueFormatterParams<BranchesWithActiveStatus>) =>
              value ? `${Number(value).toFixed(2)}` : '0.00',

            cellEditor: InputCounterCellEditorComponent,
            cellEditorParams: (
              _: ICellEditorParams<BranchesWithActiveStatus, number, InputCounterCellEditorContext>,
            ) => {
              return {
                context: {
                  min: 0,
                  step: 1,
                  precision: 1,
                },
              };
            },
            type: 'rightAligned',
            editable: params => this.isColumnEditable(params),
            onCellValueChanged: (event: NewValueParams<BranchesWithActiveStatus, number>) =>
              this.onCellValueChanged(event),
            getQuickFilterText: () => '',
            flex: 1.2,
          },
          {
            headerName: $localize`:@@grid.headers.preferred.label:Preferred Type`,
            field: 'preferred',
            cellRenderer: SwitchCellRendererComponent,
            cellRendererParams: (params: ICellRendererParams<BranchesWithActiveStatus>) => {
              const data = params.data as BranchesWithActiveStatus;

              return {
                context: {
                  size: 'normal',
                  disabled: !data.active,
                } as SwitchCellRendererContext,
              };
            },
            onCellValueChanged: (event: NewValueParams<BranchesWithActiveStatus, boolean>) => {
              const branchIndex = this.data.findIndex(({ branch }) => branch.id === event.data.branch.id);

              const preferredType = event.newValue ? PreferredType.RevenueSharing : PreferredType.Regular;

              this.data[branchIndex] = {
                ...event.data,
                preferredType,
              };

              this.branchesChanged.emit();
            },
            hide: !this.showPreferredColumn(),
            getQuickFilterText: () => '',
            flex: 0.5,
          },
        ];

        this.columnDefs.set(columnDefs);
      },
      { injector: this.#injector, allowSignalWrites: true },
    );
  }

  private readonly isColumnEditable = (params: EditableCallbackParams<BranchesWithActiveStatus>): boolean => {
    const data = params.data as BranchesWithActiveStatus;

    return data.active as boolean;
  };

  private onCellValueChanged<T>({
    newValue,
    data,
    colDef,
  }: NewValueParams<Writable<BranchesWithActiveStatus>, T>): void {
    const branchIndex = this.getBranchIndex(data.branch.id);

    if (branchIndex === -1) {
      return;
    }

    let field = colDef.field as keyof BranchesWithActiveStatus;
    let subField = '';

    if (field.includes('.')) {
      [field, subField] = field.split('.') as (keyof BranchesWithActiveStatus)[];
    }

    if (subField) {
      const updateBranchObj = data[field] as Writable<BranchesWithActiveStatus>;

      this.data[branchIndex] = {
        ...data,
        [field]: {
          ...(updateBranchObj ?? {}),
          [subField]: newValue as T,
        },
      };

      this.branchesChanged.emit();

      return;
    }

    this.data[branchIndex] = {
      ...data,
      [colDef.field as string]: newValue as T,
    };

    this.branchesChanged.emit();
  }

  private isCustomCreditPeriodType(data: BranchesWithActiveStatus): boolean {
    return data.creditPeriod?.type === CreditPeriodTypeEnum.Custom;
  }

  private getBranchIndex(branchId: string): number {
    if (!branchId) {
      return -1;
    }

    return this.data.findIndex(({ branch }) => branch.id === branchId);
  }
}
