import { capital } from 'case';
import { takeUntil } from 'rxjs';
import { ColDef, EditableCallbackParams, ValueFormatterParams, ValueSetterParams } from '@ag-grid-community/core';
import {
  ChangeDetectionStrategy,
  Component,
  computed,
  effect,
  inject,
  Injector,
  Input,
  output,
  signal,
  ViewChild,
} from '@angular/core';
import { FormControl, ValidatorFn } from '@angular/forms';

import { Destroyable, IdType } from '@supy/common';
import {
  ActionsCellRendererComponent,
  ActionsCellRendererContext,
  ConfirmDialogWithIconComponent,
  DialogService,
  GridPocComponent,
  InputCellEditorComponent,
  InputCellEditorContext,
} from '@supy/components';
import { Writable } from '@supy/core';

export type SupplierContactGridData = IdType;

export enum SupplierContactType {
  WhatsApp = 'whatsapp',
  Email = 'email',
}

@Component({
  selector: 'supy-supplier-contacts-grid',
  templateUrl: './supplier-contacts-grid.component.html',
  styleUrls: ['./supplier-contacts-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupplierContactsGridComponent extends Destroyable {
  readonly #dialogService = inject(DialogService);

  @ViewChild(GridPocComponent, { static: true }) grid: GridPocComponent<SupplierContactGridData>;

  @Input() set contacts(value: string[]) {
    if (value) {
      this.gridData.set(value.map(id => ({ id })));
    }
  }

  @Input() set contactType(value: SupplierContactType) {
    this.#type.set(value);
  }

  @Input() set isReadonly(value: boolean) {
    this.readonly.set(value);
  }

  @Input() set enableAdding(value: boolean) {
    this.enableAddingControl.setValue(value, { emitEvent: false });
  }

  @Input() readonly isAddingDisabled: boolean;
  @Input() readonly subHeaderTitle: string;
  @Input() readonly subHeaderDescription: string;
  @Input() readonly noRowsOverlayMessage: string;

  @Input() readonly inputValidators: ValidatorFn[];

  readonly addContact = output<string>();
  readonly removeContact = output<string>();
  readonly clearContacts = output<void>();
  readonly addingSwitchChanged = output<boolean>();

  readonly #injector = inject(Injector);

  protected readonly gridData = signal<SupplierContactGridData[]>([]);
  protected readonly readonly = signal<boolean>(false);

  protected readonly columnDefs = signal<ColDef<SupplierContactGridData>[]>([]);
  protected readonly defaultColumnDefs = signal<ColDef>({
    cellStyle: { border: 'none' },
  });

  readonly #type = signal<SupplierContactType | null>(null);

  readonly #typeToValueMap = new Map<SupplierContactType, string>([
    [SupplierContactType.WhatsApp, $localize`:@@suppliers.whatsapp:WhatsApp number`],
    [SupplierContactType.Email, $localize`:@@suppliers.email:email address`],
  ]);

  protected readonly addedInFooterText = computed(() => {
    return $localize`:@@suppliers.clickToAddType:Click to add ${this.#typeToValueMap.get(this.#type() as SupplierContactType) ?? 'contact'}`;
  });

  protected readonly widgetTitle = computed(() => `${capital(this.#type() as string)} Notifications`);

  readonly #canAddNewRow = computed(() => {
    return this.gridData() && this.gridData()[this.gridData().length - 1]?.id !== '';
  });

  protected readonly enableAddingControl = new FormControl<boolean>(false);

  constructor() {
    super();

    this.enableAddingControl.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(value => {
      if (!value && this.gridData().length) {
        const dialog = this.#dialogService.openDialog(ConfirmDialogWithIconComponent, {
          title: $localize`:@@suppliers.disableNotifications.title:Disable Notifications`,
          message: $localize`:@@suppliers.disableNotifications.message:Disabling supplier notifications will also remove all provided contact information. Would you still like to continue?`,
        });

        dialog.confirm.subscribe(() => {
          this.clearContacts.emit();
          this.addingSwitchChanged.emit(value as boolean);
        });

        dialog.cancel.subscribe(() => {
          this.enableAddingControl.patchValue(true, { emitEvent: false });
        });
      }
    });
  }

  protected setColumnDefs(): void {
    effect(
      () => {
        const type = this.#type() as SupplierContactType;

        this.columnDefs.set([
          {
            headerName: this.#typeToValueMap.get(type),
            field: 'id',
            valueFormatter: (params: ValueFormatterParams<SupplierContactGridData, string>) =>
              params.value ? params.value : `Enter ${this.#typeToValueMap.get(type) ?? 'contact'}`,
            cellEditor: InputCellEditorComponent,
            cellEditorParams: {
              context: {
                placeholder: `Enter ${this.#typeToValueMap.get(type) ?? 'contact'}`,
              } as InputCellEditorContext,
            },

            valueSetter: ({ data, newValue }: ValueSetterParams<Writable<SupplierContactGridData>, string>) => {
              const control = new FormControl<string>(newValue as string, [
                ...this.inputValidators,
              ]) as FormControl<string>;

              if (
                this.isContactValid(
                  control,
                  this.gridData().map(c => c.id),
                )
              ) {
                data.id = newValue as string;

                return true;
              }

              this.startEditingLastRow();

              return false;
            },
            editable: (params: EditableCallbackParams<SupplierContactGridData>) =>
              !this.readonly() && params.data?.id?.trim() === '',
            onCellValueChanged: () => this.addContact.emit(this.gridData()[this.gridData().length - 1].id),
            flex: 1,
          },
          {
            headerName: '',
            cellRenderer: ActionsCellRendererComponent,
            cellRendererParams: {
              context: {
                actions: [{ icon: 'delete', iconColor: 'error', callback: data => this.deleteContact(data) }],
              } as ActionsCellRendererContext<SupplierContactGridData>,
            },
          },
        ]);
      },
      {
        injector: this.#injector,
        allowSignalWrites: true,
      },
    );
  }

  protected onAddRow(): void {
    if (this.#canAddNewRow()) {
      this.gridData.set([...this.gridData(), { id: '' }]);
    }

    this.startEditingLastRow();
  }

  private isContactValid(control: FormControl<string>, values: string[]): boolean {
    return control?.value?.trim()?.length > 0 && control?.valid && !values?.find(v => v === control.value);
  }

  private startEditingLastRow(): void {
    setTimeout(() => {
      this.grid.api?.startEditingCell({
        rowIndex: this.gridData().length - 1,
        colKey: 'id',
      });
    });
  }

  private deleteContact(contact: SupplierContactGridData): void {
    this.gridData.set(this.gridData().filter(c => c.id !== contact.id));

    this.removeContact.emit(contact.id);
  }
}
