import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ChannelActionEnum, ResourceEnum } from '@supy.api/permissions';

import { IsAllowedRequest, PermissionHelper, PermissionStrategy, Principal, Resource } from '@supy/authz';

@Injectable({ providedIn: 'root' })
export class View extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource | undefined, principal?: Principal | undefined): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Channel,
      action: ChannelActionEnum.View,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class Link extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource | undefined, principal?: Principal | undefined): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Channel,
      action: ChannelActionEnum.Link,
      resource,
      principal,
    });
  }
}

@Injectable({ providedIn: 'root' })
export class Update extends PermissionStrategy {
  constructor(private readonly permissionHelper: PermissionHelper) {
    super();
  }

  request(resource?: Resource | undefined, principal?: Principal | undefined): Observable<IsAllowedRequest> {
    return this.permissionHelper.checkIsAllowed({
      store: this.store,
      kind: ResourceEnum.Channel,
      action: ChannelActionEnum.Update,
      resource,
      principal,
    });
  }
}

export class ChannelsPermissionsStrategy {
  static View = View;
  static Link = Link;
  static Update = Update;
}
