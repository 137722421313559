import { map, Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Query, QueryPaging, User, UserState } from '@supy/common';

import { SuppliersService } from '../../services';

@Injectable()
export class SupplierUsersResolver {
  constructor(private readonly suppliersService: SuppliersService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<User[]> | Promise<User[]> {
    const query = new Query<User>({
      paging: QueryPaging.NoLimit,
      filtering: [{ by: 'state', match: UserState.active, op: 'eq' }],
    });

    return this.suppliersService.getSupplierUsers(route.params.id as string, query).pipe(map(({ data }) => data));
  }
}
