import { firstValueFrom } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';

import { Supplier } from '@supy/common';

import { SuppliersService } from '../../services';

@Injectable()
export class SupplierResolver {
  constructor(private readonly suppliersService: SuppliersService) {}
  resolve(route: ActivatedRouteSnapshot): Promise<Supplier> {
    return firstValueFrom(this.suppliersService.getSupplier(route.params.id as string));
  }
}
