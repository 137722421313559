import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { BaseHttpService, CreateSupplierBFFRequest, IQueryResponse, Query, Supplier } from '@supy/common';
import { DetailedRetailerSupplier } from '@supy/retailers';

import { SUPPLIERS_BFF_URI } from '../config';
import { ApproveSupplierRequest, SearchSuppliersRequest } from '../core';

@Injectable({
  providedIn: 'root',
})
export class SuppliersBffService extends BaseHttpService {
  constructor(
    protected readonly httpClient: HttpClient,
    @Inject(SUPPLIERS_BFF_URI) protected readonly uri: string,
  ) {
    super(uri);
  }

  getSupplier({
    supplierId,
    retailerId,
  }: {
    supplierId: string;
    retailerId: string;
  }): Observable<DetailedRetailerSupplier> {
    return this.get(`${supplierId}/retailers/${retailerId}/detailed`);
  }

  searchSuppliers(body: SearchSuppliersRequest): Observable<IQueryResponse<Supplier>> {
    return this.post(body, 'search');
  }

  createSupplier(body: CreateSupplierBFFRequest): Observable<Supplier> {
    return this.post(body);
  }

  updateSupplier(id: string, body: CreateSupplierBFFRequest): Observable<Supplier> {
    return this.patch(body, id);
  }

  getSuppliersForReview(query: Query<Supplier>): Observable<IQueryResponse<Supplier>> {
    return this.get('review', query.toQueryParams());
  }

  approveSupplier(id: string, body: ApproveSupplierRequest): Observable<void> {
    return this.patch(body, `${id}/approve`);
  }

  mergeSuppliers(sourceId: string, destinationId: string): Observable<void> {
    return this.post(null, `${sourceId}/merge/${destinationId}`);
  }
}
