import { finalize, tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { Action, Selector, State, StateContext, StateToken } from '@ngxs/store';

import { CreateUpdateSupplierResponse, Supplier, SupplierType, User } from '@supy/common';
import { DetailedRetailerSupplier } from '@supy/retailers';

import { SuppliersBffService, SuppliersService } from '../../services';
import {
  CreateSupplier,
  CreateSupplierBff,
  DeleteSupplier,
  GetSupplierBff,
  GetSuppliers,
  GetSupplierUsers,
  ManageSupplierUsers,
  ResetCreateUpdateSupplierResponse,
  ResetSelectedSupplier,
  SearchSuppliers,
  UpdateSupplier,
  UpdateSupplierBff,
} from '../actions';

interface SuppliersStoreState {
  readonly selectedSupplier?: DetailedRetailerSupplier | null;
  readonly usersMap: Record<string, User[]>;
  readonly suppliers: Supplier[];
  readonly isLoading?: boolean;
  readonly searchSuppliers?: Supplier[];
  readonly createUpdateSupplierResponse?: CreateUpdateSupplierResponse;
}

const SUPPLIERS_STATE_TOKEN = new StateToken<SuppliersStoreState>('suppliers');

@State<SuppliersStoreState>({
  name: SUPPLIERS_STATE_TOKEN,
  defaults: {
    usersMap: {},
    suppliers: [],
    selectedSupplier: undefined,
    isLoading: false,
    searchSuppliers: [],
  },
})
@Injectable()
export class SuppliersState {
  constructor(
    private readonly suppliersService: SuppliersService,
    private readonly suppliersBffService: SuppliersBffService,
  ) {}

  @Selector()
  static isLoading(state: SuppliersStoreState) {
    return state.isLoading;
  }

  @Selector()
  static suppliers(state: SuppliersStoreState) {
    return state.suppliers;
  }

  @Selector()
  static searchSuppliers(state: SuppliersStoreState) {
    return state.searchSuppliers;
  }

  @Selector()
  static selectedSupplier(state: SuppliersStoreState) {
    return state.selectedSupplier;
  }

  @Selector()
  static isCKSupplierV1(state: SuppliersStoreState) {
    return state.selectedSupplier?.type === SupplierType.centralKitchen && !state.selectedSupplier?.isExposed;
  }

  @Selector()
  static isCKSupplierV2(state: SuppliersStoreState) {
    return state.selectedSupplier?.type === SupplierType.centralKitchen && state.selectedSupplier?.isExposed;
  }

  @Selector()
  static createUpdateSupplierResponse(state: SuppliersStoreState) {
    return state.createUpdateSupplierResponse;
  }

  @Selector()
  static suppliersMap(state: SuppliersStoreState) {
    return new Map(state.suppliers.map(supplier => [supplier.id, supplier]));
  }

  @Selector()
  static usersMap(state: SuppliersStoreState) {
    return state.usersMap;
  }

  @Action(GetSuppliers)
  getSuppliers(ctx: StateContext<SuppliersStoreState>, { query }: GetSuppliers) {
    ctx.patchState({ isLoading: true });

    return this.suppliersService.getSuppliers(query).pipe(
      tap(({ data }) => ctx.patchState({ suppliers: data })),
      finalize(() => ctx.patchState({ isLoading: false })),
    );
  }

  @Action(SearchSuppliers)
  searchSuppliers(ctx: StateContext<SuppliersStoreState>, { payload }: SearchSuppliers) {
    return this.suppliersBffService.searchSuppliers(payload).pipe(
      tap(({ data }) => {
        ctx.patchState({ searchSuppliers: data });
      }),
    );
  }

  @Action(CreateSupplierBff)
  createSupplierBff(ctx: StateContext<SuppliersStoreState>, { payload }: CreateSupplierBff) {
    return this.suppliersBffService
      .createSupplier(payload)
      .pipe(tap(createUpdateSupplierResponse => ctx.patchState({ createUpdateSupplierResponse })));
  }

  @Action(UpdateSupplierBff)
  updateSupplierBff(ctx: StateContext<SuppliersStoreState>, { id, payload }: UpdateSupplierBff) {
    return this.suppliersBffService
      .updateSupplier(id, payload)
      .pipe(tap(createUpdateSupplierResponse => ctx.patchState({ createUpdateSupplierResponse })));
  }

  @Action(GetSupplierBff, { cancelUncompleted: true })
  getSupplierBff(ctx: StateContext<SuppliersStoreState>, { payload }: GetSupplierBff) {
    return this.suppliersBffService
      .getSupplier(payload)
      .pipe(tap(supplier => ctx.patchState({ selectedSupplier: supplier })));
  }

  @Action(ResetSelectedSupplier)
  resetSelected(ctx: StateContext<SuppliersStoreState>) {
    ctx.patchState({ selectedSupplier: null });
  }

  @Action(GetSupplierUsers)
  getSupplierUsers(ctx: StateContext<SuppliersStoreState>, { id, query }: GetSupplierUsers) {
    return this.suppliersService.getSupplierUsers(id, query).pipe(
      tap(({ data: users }) => {
        const state = ctx.getState();

        ctx.patchState({ usersMap: { ...state.usersMap, [id]: users } });
      }),
    );
  }

  @Action(ManageSupplierUsers)
  manageSupplierUsers(_: StateContext<SuppliersStoreState>, { id, users, type }: ManageSupplierUsers) {
    return this.suppliersService.manageSupplierUsers(id, users, type === 'add');
  }

  @Action(CreateSupplier)
  createSupplier(_: StateContext<SuppliersStoreState>, { body }: CreateSupplier) {
    return this.suppliersService.createSupplier(body);
  }

  @Action(UpdateSupplier)
  updateSupplier(_: StateContext<SuppliersStoreState>, { id, body }: UpdateSupplier) {
    return this.suppliersService.editSupplier(id, body);
  }

  @Action(DeleteSupplier)
  deleteSupplier(_: StateContext<SuppliersStoreState>, { id }: DeleteSupplier) {
    return this.suppliersService.deleteSupplier(id);
  }

  @Action(ResetCreateUpdateSupplierResponse)
  resetCreateUpdateSupplierResponse(ctx: StateContext<SuppliersStoreState>) {
    ctx.patchState({ createUpdateSupplierResponse: undefined });
  }
}
