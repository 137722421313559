<div class="supy-supplier-branches-grid">
  <supy-grid-poc
    class="supy-supplier-branches-grid__grid"
    domLayout="normal"
    height="100%"
    [data]="data"
    [getRowHeight]="getRowHeight"
    [defaultColDefs]="defaultColDefs()"
    [columnDefs]="columnDefs()"
    (gridReady)="setColumnDefs()"
  ></supy-grid-poc>
</div>
