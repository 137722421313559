import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';

import { BaseHttpService, IQueryResponse, Query, Supplier, SupplierId, User, UserId } from '@supy/common';

import { SUPPLIERS_URI } from '../config';
import { CreateSupplierRequest, UpdateSupplierRequest, UploadImageRequest, UploadSupplierImageResponse } from '../core';
import { RequestSupplier, UploadReceiptResponse } from './../core';

@Injectable({ providedIn: 'root' })
export class SuppliersService extends BaseHttpService {
  constructor(
    protected readonly httpClient: HttpClient,
    @Inject(SUPPLIERS_URI) protected readonly uri: string,
  ) {
    super(uri);
  }

  getSuppliers(query: Query<Supplier>): Observable<IQueryResponse<Supplier>> {
    return this.get('', query.toQueryParams());
  }

  getSupplier(id: SupplierId): Observable<Supplier> {
    return this.get(id);
  }

  getSupplierUsers(id: SupplierId, query: Query<User>): Observable<IQueryResponse<User>> {
    return this.get(`${id}/users`, query.toQueryParams());
  }

  getUserRelatedSuppliers(id: UserId, query: Query<Supplier>): Observable<IQueryResponse<Supplier>> {
    return this.get(`users/${id}/related-suppliers`, query.toQueryParams());
  }

  getUserRetailersRelatedSuppliers(id: UserId, query: Query<Supplier>): Observable<IQueryResponse<Supplier>> {
    return this.get(`users/${id}/related-suppliers/all`, query.toQueryParams());
  }

  createSupplier(body: CreateSupplierRequest): Observable<Supplier> {
    return this.post(body);
  }

  requestSupplier(body: RequestSupplier): Observable<Supplier> {
    return this.post(body, 'request');
  }

  editSupplier(id: SupplierId, supplier: UpdateSupplierRequest): Observable<void> {
    return this.patch(supplier, id);
  }

  deleteSupplier(id: SupplierId): Observable<void> {
    return this.delete(id);
  }

  addUsersToSupplier(id: SupplierId, users: UserId[]): Observable<Supplier> {
    return this.patch({ users }, `${id}/users/add`);
  }

  removeUsersFromSupplier(id: SupplierId, users: UserId[]): Observable<Supplier> {
    return this.patch({ users }, `${id}/users/remove`);
  }

  manageSupplierUsers(id: SupplierId, users: UserId[], shouldAdd: boolean): Observable<Supplier> {
    if (shouldAdd) {
      return this.addUsersToSupplier(id, users);
    }

    return this.removeUsersFromSupplier(id, users);
  }

  uploadSupplierImage(body: UploadImageRequest): Observable<UploadSupplierImageResponse> {
    const formData = new FormData();

    formData.append('file', body.file);

    return this.post<FormData, UploadSupplierImageResponse>(formData, 'image');
  }

  uploadReceipt(body: FormData, requestId: string): Observable<UploadReceiptResponse> {
    return this.post<FormData, UploadReceiptResponse>(body, `upload-receipt/${requestId}`);
  }

  addUserToAllChannels(userId: string, supplierId: string) {
    return this.patch({ userId }, `${supplierId}/channels/add/user`);
  }
}
