<div class="supy-supplier-contacts-grid">
  <div class="supy-supplier-contacts-grid__header">
    <h5 class="supy-supplier-contacts-grid__header--title">{{ widgetTitle() }}</h5>
    <div class="supy-supplier-contacts-grid__sub-header">
      <div class="supy-supplier-contacts-grid__sub-header-content">
        <span class="supy-supplier-contacts-grid__sub-header--title">{{ subHeaderTitle }}</span>
        <span class="supy-supplier-contacts-grid__sub-header--description">{{ subHeaderDescription }}.</span>
      </div>
      <supy-switch [formControl]="enableAddingControl" [disabled]="isAddingDisabled"></supy-switch>
    </div>
  </div>
  <div class="supy-supplier-contacts-grid__content">
    <supy-grid-poc
      class="supy-supplier-contacts-grid__grid"
      height="13.75rem"
      domLayout="normal"
      [data]="gridData()"
      [defaultColDefs]="defaultColumnDefs()"
      [columnDefs]="columnDefs()"
      (gridReady)="setColumnDefs()"
      [addedInFooter]="enableAddingControl.value"
      [addedInFooterTitle]="addedInFooterText()"
      (addRow)="onAddRow()"
      [noRowsOverlayComponentContext]="{ message: noRowsOverlayMessage }"
    ></supy-grid-poc>
  </div>
</div>
