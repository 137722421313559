import { Observable, takeUntil } from 'rxjs';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validator,
  Validators,
} from '@angular/forms';
import { Select } from '@ngxs/store';

import {
  BaseSelectItem,
  Branch,
  BranchState,
  BranchType,
  Destroyable,
  FileValidators,
  InputValidators,
  QueryBuilder,
  Supplier,
  SupplierType,
} from '@supy/common';
import { CountriesState, Country } from '@supy/countries';
import { BranchesService } from '@supy/retailers';

export type SupplierFormControlName =
  | 'logo'
  | 'name'
  | 'address'
  | 'phone'
  | 'logoUrl'
  | 'countryId'
  | 'email'
  | 'type'
  | 'metadata'
  | 'settings';

@Component({
  selector: 'supy-supplier-form',
  templateUrl: './supplier-form.component.html',
  styleUrls: ['./supplier-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SupplierFormComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => SupplierFormComponent),
      multi: true,
    },
  ],
})
export class SupplierFormComponent
  extends Destroyable
  implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor, Validator
{
  private static readonly LIMIT = 20;

  @Input() readonly title: string;
  @Input() readonly logoUrl: string;
  @Input() readonly disabledControls: SupplierFormControlName[];
  @Output() readonly logoSaved = new EventEmitter<AbstractControl>();
  readonly form: UntypedFormGroup;
  readonly maxFileSizeKB = 100;
  readonly supplierType = SupplierType;

  branches: Branch[] = [];
  selectedWarehouseId: string;

  onTouched: () => void;
  isLoading: boolean;
  touched = false;
  disabled = false;

  @Select(CountriesState.countries) countries$: Observable<Country[]>;
  readonly types: BaseSelectItem[] = [];

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly branchesService: BranchesService,
  ) {
    super();
    this.form = new UntypedFormGroup({
      logo: new UntypedFormControl(null, [FileValidators.image(), FileValidators.maxSize(this.maxFileSizeKB)]),
      name: new UntypedFormControl(null, [Validators.required, InputValidators.names]),
      address: new UntypedFormControl(null, [Validators.required]),
      phone: new UntypedFormControl(null, [Validators.required, InputValidators.phone]),
      logoUrl: new UntypedFormControl(null),
      countryId: new UntypedFormControl(null, [Validators.required]),
      email: new UntypedFormControl(null, [InputValidators.email]),
      type: new UntypedFormControl(null, [Validators.required]),
      metadata: new UntypedFormGroup({
        taxRegistrationNumber: new UntypedFormControl(null),
        warehouseId: new UntypedFormControl(null),
      }),
      settings: new UntypedFormGroup({
        cutOffTime: new UntypedFormControl(null),
        minOrderValue: new UntypedFormControl(null),
      }),
    });
    this.types = Object.values(SupplierType).map(type => ({
      value: type,
      label: type.split('-').join(' '),
    }));
  }

  ngOnInit(): void {
    this.markDisabledControls();
  }

  ngAfterViewInit(): void {
    this.selectedWarehouseId = this.form.get('metadata')?.get('warehouseId')?.value as string;
    this.selectedWarehouseId &&
      this.branchesService
        .getBranch(this.selectedWarehouseId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe(branch => {
          this.branches = [branch];
          this.changeDetectorRef.detectChanges();
        });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.logoUrl) {
      this.form.patchValue({ logoUrl: this.logoUrl });
    }
  }

  isCkOrder(): boolean {
    return this.form.get('type')?.value === SupplierType.centralKitchen;
  }

  onTypeSelect(supplierType: SupplierType): void {
    const warehouseIdControl = this.form.get('metadata')?.get('warehouseId') as UntypedFormControl;

    if (supplierType === SupplierType.centralKitchen) {
      warehouseIdControl.addValidators([Validators.required]);
      warehouseIdControl.updateValueAndValidity();
    } else {
      warehouseIdControl.clearValidators();
      warehouseIdControl.updateValueAndValidity();
    }
  }

  onSearchBranches(searchValue?: string): void {
    const qb = new QueryBuilder<Branch>({
      paging: { limit: SupplierFormComponent.LIMIT, offset: 0 },
      filtering: [
        { by: 'state', match: BranchState.active, op: 'eq' },
        { by: 'type', match: BranchType.centralKitchen, op: 'eq' },
      ],
    });

    if (searchValue) {
      qb.filtering.withGroup('or', [
        {
          by: 'email',
          op: 'like',
          match: searchValue,
        },
        {
          by: 'name',
          op: 'like',
          match: searchValue,
        },
        {
          by: 'phone',
          op: 'like',
          match: searchValue,
        },
      ]);
    }

    this.isLoading = true;
    this.branchesService
      .getBranches(qb.build())
      .pipe(takeUntil(this.destroyed$))
      .subscribe(branches => {
        this.branches = branches.data;
        this.isLoading = false;
        this.changeDetectorRef.detectChanges();
      });
  }

  onBranchSelect(selectedWarehouses: string[]): void {
    if (selectedWarehouses.length > 0) {
      this.selectedWarehouseId = selectedWarehouses[0];
    }
  }

  onSaveLogo(): void {
    const logoControl = this.form.get('logo');

    logoControl?.markAsDirty();

    this.logoSaved.emit(logoControl || undefined);
  }

  imageRemoved(): void {
    this.form.patchValue({ logoUrl: null });
  }

  private markDisabledControls(): void {
    this.disabledControls?.forEach(control => {
      this.markAsDisabled(control);
    });
  }

  private markAsDisabled(formControlName: SupplierFormControlName): void {
    this.form.controls[formControlName].disable();
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.form.valid) {
      return null;
    }

    return { valid: false };
  }

  writeValue(value: Supplier): void {
    value &&
      this.form.reset(
        {
          ...value,
          metadata: {
            ...value.metadata,
          },
          settings: {
            ...value.settings,
          },
        },
        { emitEvent: false },
      );
  }

  registerOnChange(onChange: (value: Supplier) => void): void {
    this.form.valueChanges.subscribe(onChange);
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  markAsTouched(): void {
    if (!this.touched) {
      this.touched = true;
      this.onTouched?.();
      this.emitTouchStatusChanged();
    }
  }

  private emitTouchStatusChanged(): void {
    if (!this.form) {
      return;
    }

    const statusChanges = this.form.statusChanges as EventEmitter<string>;

    statusChanges.emit('TOUCHED');
  }
}
